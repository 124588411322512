<!--todo  后台根据时间查找未实现-->
<template>
    <div class="Level" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="90px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select placeholder="请选择" v-model="form.deptGroupCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="bd in meta.deptGroup"
                                    :label="bd.name"
                                    :value="bd.code"
                                    :key="bd.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.member.level.create')"
                >查询</el-button
            >
            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.member.level.export')"
                >导出</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />

                <el-table-column label="企业" width="240" prop="companyName" />
                <el-table-column label="机构组名称" width="240" prop="name" />
                <el-table-column label="等级数量" width="80" prop="levelCount" />
                <el-table-column label="创建人" width="200" prop="creator" />
                <el-table-column label="创建时间" width="140" prop="createTime" />
                <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.member.level.open')"
                            >查看</el-button
                        >
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.member.level.edit')"
                            >编辑</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';

export default {
    name: 'Level',
    data() {
        return {
            form: {
                search: '',
                deptGroupCode: '',
                page: 1,
                limit: Util.Limit,
            },
            meta: {
                deptGroup: [],
            },
            current: {
                typeIdx: 1,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            loading: false,
            tableData: [],
            url: {
                page: '/member/level/queryPageList',
            },
        };
    },
    mounted() {
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.deptGroup = rst;
        });
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.member.level.detail', ['会员管理', '会员等级设置', '会员等级设置详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.member.level.edit', ['会员管理', '会员等级设置', '会员等级设置编辑'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            UrlUtils.Export(this, '会员等级', '/member/level/export', this.form);
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
    },
};
</script>

<style scoped>
.Level .el-form-item {
    margin-bottom: 0;
}
</style>
